<template>
    <div class="content">
            <iframe id="unity-infame" ref="frame" src="/static/index.html" frameborder="0" scrolling="no" @click="$router.back()"></iframe>
    </div>
</template>

<script>
import '../utils/rems'
export default {
    name: 'ExhibitionWebUnity',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    height: 100vh;
}
#unity-infame{
    width: 100%;
    height: 100vh;
}
</style>